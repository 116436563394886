import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using react-router for navigation
import '../styles/minute.css';

const Strategy = () => {
    return (
        <div className="content-container">
            <h1 className="centered-text">Strategy</h1>
            <div className="justified-text">
                <p>Strategy:</p>
                <ul className="strategy-links">
                    <li><Link to="/strategy2">Entrepreneurship</Link></li>
                    <li><Link to="/bap">Business analysis process</Link></li>
                    <li><Link to="/value">Two questions on industry analysis</Link></li>
                    <li><Link to="/great">Three rules for a great company</Link></li>
                    <li><Link to="/teamwork">Six common misperceptions about teamwork</Link></li>
                    <li><Link to="/claimsandparticulars">Law: Claim forms and particulars of claim</Link></li>
                    <li><Link to="/witnessstatements">Law: Witness statements</Link></li>
                    <li><Link to="/groundsandskeletons">Law: Grounds of appeal and Skeleton arguments</Link></li>
                    <li><Link to="/law">Law: All statements together</Link></li>
                </ul>
            </div>
        </div>
    );
};

export default Strategy;
