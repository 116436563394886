import React, { useState, useRef, useEffect } from 'react';
import StrategySystemsAndObjectives from './bap/StrategySystemsAndObjectives';
import InvestigateSituation from './bap/InvestigateSituation';
import ConsiderPerspectives from './bap/ConsiderPerspectives';
import AnalyseNeeds from './bap/AnalyseNeeds';
import EvaluateOptions from './bap/EvaluateOptions';
import RequirementsElicitation from './bap/RequirementsElicitation';
import RequirementsAnalysis from './bap/RequirementsAnalysis';
import RequirementsDevelopment from './bap/RequirementsDevelopment';
import RequirementsModelling from './bap/RequirementsModelling';
import ManageChange from './bap/ManageChange';
import '../../styles/minute.css';

const Bap = () => {
    const [modalImage, setModalImage] = useState('');
    const modalRef = useRef(null);

    const handleImageClick = (src) => {
        setModalImage(src);
        if (modalRef.current) {
            modalRef.current.classList.add('show');
            modalRef.current.style.display = 'block';
            modalRef.current.setAttribute('aria-hidden', 'false');
        }
    };

    const handleClose = () => {
        if (modalRef.current) {
            modalRef.current.classList.remove('show');
            modalRef.current.style.display = 'none';
            modalRef.current.setAttribute('aria-hidden', 'true');
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className="content-container">
            <h2 className="centered-text">An overview of business analysis process models</h2>
            <div className="justified-text">
                <StrategySystemsAndObjectives onImageClick={handleImageClick} />
                <InvestigateSituation onImageClick={handleImageClick} />
                <ConsiderPerspectives onImageClick={handleImageClick} />
                <AnalyseNeeds onImageClick={handleImageClick} />
                <EvaluateOptions onImageClick={handleImageClick} />
                <RequirementsElicitation onImageClick={handleImageClick} />
                <RequirementsAnalysis onImageClick={handleImageClick} />
                <RequirementsDevelopment onImageClick={handleImageClick} />
                <RequirementsModelling onImageClick={handleImageClick} />
                <ManageChange onImageClick={handleImageClick} />
            </div>
            <div className="modal fade" id="modalImage" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close custom-close" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <img id="modalImageContent" src={modalImage} alt="Preview" className="img-fluid modal-image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bap;