import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import '../styles/minute.css';

const Systems = () => {
    return (
        <div className="content-container">
            <h1 className="centered-text">Systems</h1>
            <div className="justified-text">
                <p>Systems consultancy:</p>
                <ul className="systems-links">
                    <li><Link to="/bap">Business analysis process</Link></li>
                    <li><Link to="/electronics">From hardware to software: diodes, resistors, switches, capacitors, transistors</Link></li>
                    <li><Link to="/ktps">100,000 transactions per second</Link></li>
                    <li><Link to="/threads">Threads and threading</Link></li>
                    <li><Link to="/fxmm">FX and MM training</Link></li>
                    <li><a href="https://aci-uk.com/aci-australia-dealing-simulation-2024/" target="_blank" rel="noopener noreferrer">The ACI Dealing Simulation Course</a></li>
                    {/* Uncomment below if needed */}
                    {/* <li><Link to="/ba">Business Analysis techniques</Link></li> */}
                    <li><Link to="/arch">How to write a system architecture document</Link></li>
                    <li><Link to="/is">BSc systems theory</Link></li>
                </ul>
            </div>
        </div>
    );
};

export default Systems;




