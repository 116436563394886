import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/minute.css';

const Great = () => {
    return (
        <div className="content-container">
            <h2 className="centered-text">Some rules for strategy</h2>
            <div className="justified-text">
<p align="justify">
             My business research distills many
        strategy reports down to the following:

        <br/>
<br/>     1. Close to the customer: If your
        customers succeed, so do you. Let them drive strategy.

        <br/>     2. Actions add value: Be
        experimental, work in stages, manage risks, but get on with it. Too much
        planning creates analysis paralysis.

        <br/>     3. Create a culture: If you don't it
        will create itself. I like the balanced scorecard, and "the three rules"
        see below.

        <br/>     4. Ethical vision: Try and follow
        the Golden Rule in dealings with all customers, competitors,
        stakeholders and employers.

        <br/>     5. Think differently: But you can't
        do any better if everyone is the same as everyone else.

        <br/>     6. Seek insight: Insight makes your
        company low risk, and drives performance and sales.

        <br/>     7. Work intensely: Because intensity
        separates the best from the average.

        <br/>     8. Enjoy your work: it gives you a
        long term competitive advantage over someone who doesn't.
      </p>
<p align="justify">
             Raynor and Ahmed show in their book "the
        three rules"

        <br/>
<br/>     1. Better before cheaper: in other
        words, compete on differentiators other than price.

        <br/>     2. Revenue before cost: that is,
        prioritize increasing revenue over reducing costs.

        <br/>     3. There are no other rules: so
        change anything you must to follow Rules 1 and 2.

        <br/>     See also <a href="https://hbr.org/2013/04/three-rules-for-making-a-company-truly-great/">their HBR article</a>
</p>

            </div>
        </div>
    );
};

export default Great;
