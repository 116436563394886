import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/footer.css';

function Footer() {
    const navigate = useNavigate(); // useNavigate hook

    // Function to handle back navigation
    const handleBackClick = () => {
        navigate(-1);
    };

    // Function to handle navigation to data policy
    const handleDataPolicyClick = () => {
        navigate('/data');
    }

    return (
        <footer className="border-top py-3 w-100 mt-5 text-center footer-class">
            <button onClick={handleBackClick} className="footer-btn">Back</button>
            <span className="text-muted">© 2024 Webster Systems Ltd</span>
            <button onClick={handleDataPolicyClick} className="footer-btn">Data Policy</button>
        </footer>
    );
}

export default Footer;


