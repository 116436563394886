import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using react-router for navigation
import '../styles/minute.css';

const Training = () => {
    return (
        <div className="content-container">
            <h1 className="centered-text">Finance Training</h1>
            <div className="justified-text">
                <p>Finance training:</p>
                <ul className="training-links">
                    <li><Link to="/fxmm" className="justified-text">FX and MM training</Link></li>
                    <li><a href="https://aci-uk.com/aci-australia-dealing-simulation-2024/" 
                        target="_blank" rel="noopener noreferrer" className="justified-text">
                            The ACI Dealing Simulation Course
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Training;

