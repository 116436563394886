import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/minute.css';

const Law = () => {
    return (
        <div className="content-container">
            <h2 className="centered-text">Claim documents before a judge (don't try and anticipate a defence except limitations)</h2>
            <div className="justified-text">
<section className="law">
<table cellpadding="10" cellspacing="0" width="100%">
<thead>
<tr>
<th>Claim forms</th>
<th>Particulars of claim</th>
<th>Witness statements</th>
<th>Grounds of appeal</th>
<th>Skeleton arguments</th>
</tr>
</thead>
<tr>
<td valign="top">
              The <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part16/pd_part16#2">claim form</a> generally 
              gives names and addresses for service
            </td>
<td valign="top">
              The claimant must <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part16/pd_part16#8">specifically set out the following matters</a> in 
              his particulars of claim where he wishes to rely on them in support of his claim:

              <ul>
<li>any allegation of fraud</li>
<li>the fact of any illegality</li>
<li>details of any misrepresentation</li>
<li>details of all breaches of trust</li>
<li>notice or knowledge of a fact</li>
<li>details of unsoundness of mind or undue influence</li>
<li>details of wilful default</li>
<li>any facts relating to mitigation of loss or damage</li>
</ul>
</td>
<td valign="top">
              A <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part32#32.8">witness statement</a> is 
              a written statement signed by a person which contains the <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part32#32.4">evidence</a> which 
              that person would be allowed to give orally.
            </td>
<td valign="top">
              (1) The <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part52/practice-direction-52c-appeals-to-the-court-of-appeal#5.1">grounds of appeal</a> must 
              identify as precisely as possible the respects in which the
              judgment of the court below is (a) wrong, or (b) unjust because of
              a serious prodecural or other irregularity, as required by <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part52#52.11">CPR rule 52.11(3)</a>.

              <p>
                (2) The reasons why the decision under appeal is wrong or unjust
                must not be included in the grounds of appeal and must be
                confined to the skeleton argument.
              </p>
</td>
<td>
<p>
                (1) The <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part52/pd_part52#V">skeleton argument</a> is 
                to assist the court by setting out as concisely as
                practicable the arguments upon which a party intends to rely.
              </p>
<p>(2) A skeleton argument must:</p>
<ul>
<li>be concise</li>
<li>define and confine the areas of controversy</li>
<li>be set out in numbered paragraphs</li>
<li>
                  be cross-referenced to any relevant document in the bundle
                </li>
<li>
                  be self-contained and not incorporate by reference material
                  from previous skeleton arguments
                </li>
<li>
                  not include extensive quotations from documents or authorities
                </li>
</ul>
<p>(3) Documents to be relied apon must be identified</p>
<p>
                (4) Where it is necessary to refer to an authority, the skeleton
                argument must (a) state the proposition of law the authority
                demonstrates; and (b) identify the parts of the authority that
                support the proposition. If more than one authority is cited in
                support of a given proposition, the skeleton argument must
                briefly state why.
              </p>
</td>
</tr>
</table>
</section>

            </div>
        </div>
    );
};

export default Law;
