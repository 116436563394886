import React from 'react';

const InvestigateSituation = ({ onImageClick }) => {
    return (
        <div className="investigate-situation">
            <p className="p-text">
                <u>Investigate situation:</u> What's the scope and system boundary? The problems, issues and
                opinions?
            </p>
            <div className="spacing">
                Uncovering problems and issues requires qualitative or quantitative analysis and documentation of
                the results. Qualitative research focusses on the people involved, their hopes and fears, the range
                of facts and opinions, and the perspectives on change (allies or opponents). Techniques for
                qualitative research includes interviews, workshops and observations. Quantitative research focusses
                on data metrics, data frequency, the numbers, data samples and testing. Techniques include
                questionnaires, samples and document analysis. Documenting the results is more than writing up
                formal reports and can include mind maps, rich pictures, and context diagrams.
            </div>
            <div className="investigate-situation-table table-divider">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Interviewing</th>
                            <th>Workshopping</th>
                            <th>Data sampling</th>
                            <th>Rich pictures</th>
                            <th>Mind maps</th>
                            <th>Context diagrams</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tooltip-cell"><img src="../images/interview.gif" onClick={() => onImageClick('../images/interview.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/workshop.gif" onClick={() => onImageClick('../images/workshop.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/sample.gif" onClick={() => onImageClick('../images/sample.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/rich_picture.gif" onClick={() => onImageClick('../images/rich_picture.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/mind_map.gif" onClick={() => onImageClick('../images/mind_map.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/context_diagram.gif" onClick={() => onImageClick('../images/context_diagram.gif')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InvestigateSituation;


