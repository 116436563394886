import React from 'react';

const RequirementsModelling = ({ onImageClick }) => {
    return (
        <div className="requirements-modelling">
            <p className="p-text">
                <u>Requirements IV, Modelling:</u> Exactly what are we doing?
            </p>
            <div className="spacing">
                A use case can describe a unit of functionality in a system. Depending on the amount of detail they
                could be defined as diagrams or descriptions or both. Entity Relationship Diagrams and Logical Data
                Models conceptually represent data and relationships between data items (entities) in a system.
                Class models show data in terms of name, attributes and operations on the data (behaviour), as well
                as associations between classes. A CRUD matrix links use cases and class models in terms of Create,
                Read, Update and Delete operations on data.
            </div>
            <div className="requirements-modelling-table table-divider">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Use case diagram</th>
                            <th>Cockburn's use case</th>
                            <th>Data relations modelling</th>
                            <th>Class diagram</th>
                            <th>Class model</th>
                            <th>CRUD analysis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tooltip-cell"><img src="../images/use_case_diagram.gif" onClick={() => onImageClick('../images/use_case_diagram.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/use_case_description.gif" onClick={() => onImageClick('../images/use_case_description.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/data_relations.gif" onClick={() => onImageClick('../images/data_relations.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/class_model.gif" onClick={() => onImageClick('../images/class_model.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/class_model2.gif" onClick={() => onImageClick('../images/class_model2.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/crud.gif" onClick={() => onImageClick('../images/crud.gif')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RequirementsModelling;
