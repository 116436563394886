import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Container from './pages/Container';
import Intro from './pages/Intro';
import Strategy from './pages/Strategy';
import Systems from './pages/Systems';
import Training from './pages/Training';
import Contact from './pages/Contact';
import Data from './pages/Data';
import Pension from './pages/Pension';

// Topics
import Ktps from './pages/topics/100ktps';
import Arch from './pages/topics/Arch';
import Bap from './pages/topics/Bap';
import ClaimsAndParticulars from './pages/topics/ClaimsAndParticulars';
import Electronics from './pages/topics/Electronics';
import Fxmm from './pages/topics/Fxmm';
import Great from './pages/topics/Great';
import GroundsAndSkeletons from './pages/topics/GroundsAndSkeletons';
import Is from './pages/topics/Is';
import Law from './pages/topics/Law';
import Strategy2 from './pages/topics/Strategy';
import Teamwork from './pages/topics/Teamwork';
import Threads from './pages/topics/Threads';
import Value from './pages/topics/Value';
import WitnessStatements from './pages/topics/WitnessStatements';

const App = () => {
  return (
    <Container>
      <Routes>
        <Route path="/" element={<Intro/>} />
        <Route path="/strategy" element={<Strategy/>} />
        <Route path="/systems" element={<Systems/>} />
        <Route path="/training" element={<Training/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/data" element={<Data/>} />
        <Route path="/pension" element={<Pension/>} />

        // Topic routes
        <Route path="/ktps" element={<Ktps />} />
        <Route path="/arch" element={<Arch />} />
        <Route path="/bap" element={<Bap />} />
        <Route path="/claimsandparticulars" element={<ClaimsAndParticulars />} />
        <Route path="/electronics" element={<Electronics />} />
        <Route path="/fxmm" element={<Fxmm />} />
        <Route path="/great" element={<Great />} />
        <Route path="/groundsandskeletons" element={<GroundsAndSkeletons />} />
        <Route path="/is" element={<Is />} />
        <Route path="/law" element={<Law />} />
        <Route path="/strategy2" element={<Strategy2 />} />
        <Route path="/teamwork" element={<Teamwork />} />
        <Route path="/threads" element={<Threads />} />
        <Route path="/value" element={<Value />} />
        <Route path="/witnessstatements" element={<WitnessStatements />} />
      </Routes>
    </Container>
  );
}

export default App;
