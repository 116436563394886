import React from 'react';

const AnalyseNeeds = ({ onImageClick }) => {
    return (
        <div className="analyse-needs">
            <p className="p-text">
                <u>Analyse needs:</u> Where do we need to be?
            </p>
            <div className="spacing">
                Before simply setting actions to solve current problems, think about the broader stakeholder visions
                for the business system. By only addressing the current problems you can miss greater opportunities.
                Consider modelling business processes, business events and operational rules by, for example, using
                Porter's value chain, business process analysis, swimlanes, decision trees or tables. Then apply gap
                analysis to identify actions to take.
            </div>
            <div className="analyse-needs-table table-divider">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Value proposition analysis</th>
                            <th>Porter's value chains</th>
                            <th>Business context diagram</th>
                            <th>UML activity diagrams (swimlanes)</th>
                            <th>Business rules analysis</th>
                            <th>Decision trees</th>
                            <th>Gap analysis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tooltip-cell"><img src="../images/value.gif" onClick={() => onImageClick('../images/value.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/value_chains.gif" onClick={() => onImageClick('../images/value_chains.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/business_context.gif" onClick={() => onImageClick('../images/business_context.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/swimlanes.gif" onClick={() => onImageClick('../images/swimlanes.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/business_rules.gif" onClick={() => onImageClick('../images/business_rules.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/decision_tree.gif" onClick={() => onImageClick('../images/decision_tree.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/gap_analysis.gif" onClick={() => onImageClick('../images/gap_analysis.gif')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AnalyseNeeds;
