import React from 'react';

const ManageChange = ({ onImageClick }) => {
    return (
        <div className="manage-change">
            <p className="p-text">
                <u>Manage change:</u> How is it going?
            </p>
            <div className="spacing">
                The last stage of the BA process is to introduce and/or manage the changes and realise and manage
                the benefits of change, and to articulate the benefits. It involves cultural analysis, unfreezing
                and transition, the SARAH model (Shock, Anger, Rejection, Acceptance, Hope) and/or a learning cycle
                or new competency model. In benefits analysis, a project can so often be managed on objectives of
                delivery dates and budget that the original business objectives are lost. A benefits map can show
                how changes lead to benefits, and a benefits chart showing changes and benefits against time can
                help manage a project. A benefits plan consists of (a) vision, (b) map and chart, (c) performance
                measures, (d) financial analysis, (e) dependencies between benefits, (f) benefit tracking and
                reporting and (g) owners tasked with achieving the benefits. While a project plan is concerned with
                technical issues and activities required to execute a project, the benefits plan is concerned with
                the activities required to achieve the benefits. The theory is major project decisions ought to be
                made with reference to achieving the benefits plan as well as the project plan.
            </div>
            <div className="manage-change-table table-divider">
                <table className="table">
                    <thead>
                        <tr>
                            <th>SARAH change model</th>
                            <th>Deal Kennedy culture</th>
                            <th>Honey Mumford culture</th>
                            <th>Conscious competence model</th>
                            <th>Benefits map</th>
                            <th>Benefits plan</th>
                            <th>Kaplan's Balanced scorecard</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tooltip-cell"><img src="../images/change_model.gif" onClick={() => onImageClick('../images/change_model.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/deal_kennedy.gif" onClick={() => onImageClick('../images/deal_kennedy.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/honey_mumford.gif" onClick={() => onImageClick('../images/honey_mumford.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/competence.gif" onClick={() => onImageClick('../images/competence.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/benefits_map.gif" onClick={() => onImageClick('../images/benefits_map.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/benefits_plan.gif" onClick={() => onImageClick('../images/benefits_plan.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/balanced_scorecard.gif" onClick={() => onImageClick('../images/balanced_scorecard.gif')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ManageChange;
