import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/minute.css';

const Arch = () => {
    return (
        <div className="content-container">
            <div className="justified-text">
<main className="content-container">
<h2 className="centered-text">How to write a system architecture document</h2>
<br />
<p align="justify">
             The idea is to discover primary use cases
        for the system, which identifies the main component areas, as loosely
        coupled areas of functionality. Then to write "test cases" (or first
        person use cases) for each component, giving us gap analysis of current
        to future state, actions to achieve the future state, and a roadmap.
        Then take internal, external and global views of the system, about how
        each component is delivered. Finally look at measures for component
        resilience, performance and security. Finish off with a FAQ.
      </p>
<p align="justify">
             1. Overview: a one to three page summary
        of the deliverables across the architecture.
      </p>
<p align="justify">
             2. Introduction: the purpose, scope,
        audience, and sources for the document.
      </p>
<p align="justify">
             3. Business drivers: Provide very brief
        business use cases.
      </p>
<p align="justify">
             4. Technology drivers: Provide the
        technology use cases.
      </p>
<p align="justify">
             5. Very High Level Architecture: Write up
        a primary business use case across the architecture. Identify the
        components in the system and draw up a rich picture of the components
        and their boundaries.
      </p>
<p align="justify">
             6. Component test cases: Write up a series
        of first person test cases for each component.
      </p>
<p align="justify">
             7. Current state and future state: Begin
        to identify gaps between the two states, as actions towards convergence.
      </p>
<p align="justify">
             8. The views: discuss the system as viewed from different points of view.<br/><br/>
        <ul className="ul-top">
          <li>Client view</li>
          <li>Staff view</li>
          <li>Global view</li>
          <li>Resilience view
          <br/><ul><li>Failover</li><li>Recovery</li><li>Disaster Recovery</li></ul>
          </li>
          <li>Performance view</li>
          <li>Security view</li>
        </ul>
      </p>
<p align="justify">
             9. FAQ: all the questions and all the answers.
      </p>
</main>

            </div>
        </div>
    );
};

export default Arch;
