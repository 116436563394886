import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../../styles/minute.css';

const Electronics = () => {
    return (
        <div className="content-container">
            <h2 className="centered-text">From hardware to software: diodes, resistors, switches, capacitors, transistors</h2>
            <div className="justified-text">
<div className="text-content">
        The transistor was invented out of the diode. A diode allows current to
        flow in one direction but not the opposite. That means the diode
        effectively has two values of resistance: either low resistance allowing
        current to flow (on) or high resistance not allowing current to flow (off).<br />
        <img alt="Diode" className="centered-image" src="../images/diode.gif"/>
<p>
          After the diode was discovered then an obvious research question was
          what kind of diode could have multiple resistance values? From that
          question the transistor (a transforming resistor) was invented. Put
          simply, the output resistance of a transistor is transformed by
          applying a charge to the base signal. The transistor consists of an
          input, a base and and output known as the collector, base and emitter.

          <img alt="Transistor" className="centered-image" src="../images/transistor.gif"/>
</p>
<p>
          Consider that the transistor can "transform" resistance in a circuit
          by acting as a kind of dynamic resistor, and then take a simple
          "voltage divider" circuit. In a simple voltage divider circuit the
          output voltage is divided depending on two resistors fixed between the
          input voltage, the output voltage and earth. The "divided" output
          voltage always remains fixed because the resistor setup cannot change.
          At least, that was the story before the transistor.<br/>

          <img alt="Voltage divider" className="centered-image" src="../images/divider.gif"/><br/>

          Once one of the resistors in a voltage divider is replaced with
          a transistor it becomes possible to dynamically change the output
          voltage depending on whether a base signal is applied to the
          transistor. For example, when the transistor is set at very low
          resistance between input voltage and earth the output voltage is ZERO
          (current simply flows to earth) and when the transistor is set at very
          high resistance between input voltage and earth (current cannot flow
          to earth) the output voltage is the INPUT VOLTAGE. Substituting 0 for
          ZERO and 1 for the INPUT VOLTAGE we see the start of binary signal
          processing.
        </p>
<p>
          This begins to show why the transistor was the invention of the 20th
          century. The outputs of transistor enabled circuits follow truth
          tables:

          <img alt="Truth table" className="centered-image wide-image" src="../images/truth.gif"/>
</p>
<p>
          A NOT gate has 1 transistor, NAND, NOR, AND and OR take 2 or 3
          transistors. When the basic logic gates are combined with switches
          they produce adders and half adders, and so on.
          <p className="image-row">
            <img alt="And gate" className="centered-image" src="../images/and_gate.gif"/>
            <img alt="Or gate" className="centered-image" src="../images/or_gate.gif"/>
            <img alt="Not gate" className="centered-image" src="../images/not_gate.gif"/>
          </p>
</p>
<p>
          When transistors are combined with capacitors it produces memory.
          Indeed, in DRAM 1 bit takes 1 transistor and 1 capacitor to store. Now
          things could get useful to know when coding. Having said that, DRAM
          memory loses charge once the power's turned off, so persistent memory
          requires more components per bit storage.<br/>
          <p className="image-row">
            <img alt="DRAM" className="centered-image" src="../images/dram.gif"/>
            <img alt="SRAM" className="centered-image" src="../images/sram.gif"/>
          </p>
</p>
<p>
          Conclusions? For the purposes of ultra fast systems the programming
          language employed ought to be able to consider exactly how many logic
          gates are used by the circuits completed for each program instruction.
          Extrapolate to measure how many transistors, switches and capacitors
          are used throughout the all circuits completed by a program. Given
          that an int in Java takes 32 bits, we know that to store an int in
          DRAM takes 32 transistors and 32 capacitors. Further, a 32 bit
          instruction set takes 32 transistors to store in DRAM and a 64 bit
          instruction set takes 64. Presumably the CPU registers are also one
          transistor and one capacitor per bit.
        </p>
</div>

            </div>
        </div>
    );
};

export default Electronics;
