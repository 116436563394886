import React from 'react';

const ConsiderPerspectives = ({ onImageClick }) => {
    return (
        <div className="consider-perspectives">
            <p className="p-text">
                <u>Consider perspectives:</u> Who is everyone involved?
            </p>
            <div className="spacing">
                Working with stakeholders is crucial. The BA must identify stakeholders and interested parties so
                that a full range of views can be understood and later conflict avoided in the case of new or
                missing points of view. Once the people involved are known then examining their levels of influence
                and areas of concern allows the BA to identify areas of potential conflict, misunderstanding,
                communication and even resistance. Finally, stakeholder management techniques carried out during a
                project help everyone to work together in a changing environment, including planning, conflict
                resolution and principled negotiation. A 'Stakeholder Wheel' groups stakeholders as customers,
                partners, suppliers, regulators, employees, managers, owners and competitors. For analysis consider
                organisation diagrams, CATWOE and BAM. For negotiating, establish a party's BATNA (Best Alternative
                To Negotiated Agreement).
            </div>
            <div className="consider-perspectives-table table-divider">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Stakeholder wheel</th>
                            <th>Stakeholder power interest</th>
                            <th>Checkland's CATWOE</th>
                            <th>Business activity modelling</th>
                            <th>Thomas Kilmann conflict modes</th>
                            <th>Ury and Fisher's BATNA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tooltip-cell"><img src="../images/wheel.gif" onClick={() => onImageClick('../images/wheel.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/power_interest.gif" onClick={() => onImageClick('../images/power_interest.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/catwoe.gif" onClick={() => onImageClick('../images/catwoe.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/bam.gif" onClick={() => onImageClick('../images/bam.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/thomas_kilmann.gif" onClick={() => onImageClick('../images/thomas_kilmann.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/batna.gif" onClick={() => onImageClick('../images/batna.gif')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ConsiderPerspectives;
