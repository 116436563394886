import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/minute.css';

const WitnessStatements = () => {
    return (
        <div className="content-container">
            <h1 className="centered-text">Witness statements</h1>
            <div className="justified-text">

<table cellpadding="10" cellspacing="0" width="100%">
<thead>
<tr>
</tr>
</thead>
<tr>
<td valign="top">



                    A <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part32#32.8">witness
                        statement</a> is a written statement signed by a person which contains 
                        the <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part32#32.4">evidence</a> which 
                        that person would be allowed to give orally.

                </td>
</tr>
</table>
            </div>
        </div>
    );
};

export default WitnessStatements;
