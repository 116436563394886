import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../styles/hero.css';

const Hero = () => {
    const navigate = useNavigate();

    return (
        <div className="hero-content">
            <Row>
                <Col>
                    <h2 className="centered-text">Strategy</h2>
                    <img src="/images/strategy2.jpg" alt="IT Strategy" className="img-fluid img-fixed-height" onClick={() => navigate('/strategy')} />
                </Col>
                <Col>
                    <h2 className="centered-text">IT Systems</h2>
                    <img src="/images/overview3.jpg" alt="Systems" className="img-fluid img-fixed-height" onClick={() => navigate('/systems')} />
                </Col>
                <Col>
                    <h2 className="centered-text">Training</h2>
                    <img src="/images/speculators2.jpg" alt="Training" className="img-fluid img-fixed-height" onClick={() => navigate('/training')} />
                </Col>
            </Row>
        </div>
    );
};

export default Hero;
