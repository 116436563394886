import React from 'react';

const EvaluateOptions = ({ onImageClick }) => {
    return (
        <div className="evaluate-options">
            <p className="p-text">
                <u>Evaluate options:</u> How do we get there?
            </p>
            <div className="spacing">
                Once tactics are decided on, the situation is modelled, stakeholders identified, and actions to meet
                needs are derived, then a BA can consider and present options for change. To make a business case
                (a) identify options, (b) reduce into a shortlist, (c) prepare the business case and (d) present to
                management. This may include feasibility studies, cost benefit analysis (tangible and intangible),
                investment appraisal (breakeven analysis, NPV and IRR) and business case reporting. To make a
                presentation (1) Tell 'em what you're going to tell 'em (2) Tell 'em and then (3) Tell 'em what
                you've told 'em.
            </div>
            <div className="evaluate-options-table table-divider">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Feasibility analysis</th>
                            <th>Force field analysis</th>
                            <th>Cost benefit analysis</th>
                            <th>Risk analysis</th>
                            <th>Investment appraisal</th>
                            <th>Internal rates of return</th>
                            <th>Business case preparation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tooltip-cell"><img src="../images/feasibility.gif" onClick={() => onImageClick('../images/feasibility.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/force_field.gif" onClick={() => onImageClick('../images/force_field.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/cost_benefit.gif" onClick={() => onImageClick('../images/cost_benefit.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/impact.gif" onClick={() => onImageClick('../images/impact.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/appraisal.gif" onClick={() => onImageClick('../images/appraisal.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/irr.gif" onClick={() => onImageClick('../images/irr.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/business_case.gif" onClick={() => onImageClick('../images/business_case.gif')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EvaluateOptions;

