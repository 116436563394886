import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../styles/minute.css';

const Intro = () => {
    return (
        <div className="justified-text">
            <Row>
                <Col md={4}>
                    <section className="general img-center">
                        <img src="../images/ws.jpg" alt="Webster Systems Ltd" />
                    </section>
                </Col>
                <Col md={7}>
                    <br />
                    <br />
                    <br />
                    <h1>Welcome to Webster Systems Ltd</h1>
                    <p>
                        Pioneering IT strategy and software development to reduce complexity and create long term competitive advantage.
                    </p>
                    <p>
                        Webster Systems provides a full range of IT services to help businesses achieve their goals.
                        From custom software development to IT consulting, we have the expertise and experience.
                    </p>
                    <p>
                        Recent projects involve developing FIX engines for IRS dealing, and migrating business software to the cloud.
                    </p>
                </Col>
            </Row>
        </div>
    );
};

export default Intro;

