import React from "react";
import { Link } from "react-router-dom";
import "../../styles/minute.css";

const Is = () => {
  return (
    <div className="content-container">
      <h2 className="centered-text">BSc Information systems</h2>
      <div className="justified-text">
        <article>
          <p>
            It is worth considering that the history of information systems is
            in the administration of messengers, pictures, letters, books, paper
            and spoken communications between organisations. This helps a modern
            day analyst to keep a perspective on the human processes in the
            implementation of new information systems. The latest technology
            will not make poor business plans succeed. However, gathering data,
            interpreting information and disseminating knowledge faster, more
            concisely and more accurately is of interest to organisations.
            According to MIT business school, modern IT projects involve a
            mixture of four different management objectives: cost reduction,
            better information, shared infrastructure and competitive advantage.
            Another recent buzzword is "reduce complexity".
          </p>
          <p>
            There are competing schools of thought on how data, information and
            knowledge processing systems can be modelled in order to maximise
            value, which raise a number of questions for managing IT projects.
          </p>
          <p><ul>
            <li> How do we model existing data, information and knowledge processing
            systems? </li>         
            <li> What are their present costs and revenues? With uncertain
            outcomes, how can an analyst quantify marginal costs and revenues of
            a new system?</li>
            <li> Is a new systems project feasible?</li>
            <li> If a project is feasible, what development techniques
            maximise project value?</li>
            <li> What stages are there in development?</li>
            <li> What are the likely problems and benefits involved with each
            stage of the project?</li>
            <li> Who are the people involved? What are the critical success
            factors?</li>
            <li> What is the impact of new technology? What happens after
            installation?</li>
            </ul>
          </p>
          <p>
            A methodology which best investigates these unknowns is the systems
            analyst's chief business process.
          </p>
          <h3>A system analyst's approach to systems theory</h3>
          <ul>
            <li>
              Research and understand existing systems, both manual and
              automated. Use rich pictures, context diagrams, systems boundary
              diagrams.
            </li>
            <li>
              Identify areas where automation is possible. Use P&amp;L, ROI, NPV
              analysis, operational costs and revenues figures for each
              identifiable system.
            </li>
            <li>
              Define requirements for automation. List the goals of the system.
            </li>
            <li>
              Develop a set of possible technical and business systems options.
            </li>
            <li>
              Define the details of the chosen option. Use OO design, Data Flow
              diagrams, Logical Data Structures. This defines the details of the
              architecture you will use.
            </li>
            <li>
              Prototype your code and test the system to make sure that you
              obtain the desired results.
            </li>
            <li>Implement the system.</li>
            <li>Monitor operations, maintain and support the system.</li>
          </ul>
          <h3>A system analyst's approach to problem solving</h3>
          <ul>
            <li>Research and understand the problem</li>
            <li>
              Verify that the benefits of solving the problem outweigh the costs
            </li>
            <li>Define the requirements for solving the problem</li>
            <li>Develop a set of possible solutions (alternatives)</li>
            <li>Define the details of the chosen solution</li>
            <li>Monitor to make sure that you obtain the desired results</li>
            <li>Decide which solution is best and make a recommendation</li>
            <li>Implement the solution</li>
          </ul>
          <h3>Information models</h3>
          <p className="image-row">
            <img
              alt="Systems modelling"
              className="centered-image"
              src="../images/techs.gif"
            />
            <img
              alt="OO modelling"
              className="centered-image"
              src="../images/oo_design_relationships.gif"
            />
          </p>
          <br />

          <h3>What's missing here?</h3>
          <p>
            The missing element in the above is identifying, examining,
            negotiating with and managing the stakeholders in the system. Who is
            everyone? See <a href="bap.htm">Business Analysis process</a>
          </p>
        </article>
      </div>
    </div>
  );
};

export default Is;
