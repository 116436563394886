import React from 'react';
import openPopup from '../services/popupDisplay';
import '../styles/minute.css';
import '../styles/images.css'; 

const pdf = {
    deed: "/pdf/Webster Systems Ltd Pension Scheme Rules.pdf",
    rules: "/pdf/Webster Systems Ltd Pension Scheme Rules.pdf",
    investment: "/pdf/Webster Systems Ltd Pension Scheme Rules.pdf"
};

const Data = () => {
    return (
        <div className="content-container justified-text">
            <section className="general img-center">
                <img src="../images/pension.jpg" alt="Pension Scheme" />
            </section>

            <h1 className="centered-text">Webster Systems Ltd Pension Scheme</h1>
            <br />
            <p>Last updated: 2024</p>
            <p>
                At Webster Systems Ltd, we run a Small Self Administered Pension for the workplace. See below for the Trust Deed, Scheme Rules and Investment Principles.
            </p>
            <a href={pdf.deed} target="_blank" rel="noopener noreferrer">Trust Deed</a>
            <br />
            <a href={pdf.rules} target="_blank" rel="noopener noreferrer">Scheme Rules</a>
            <br />
            <a href={pdf.investment} target="_blank" rel="noopener noreferrer">Investment Principles</a>
        </div>
    );
};

export default Data;
