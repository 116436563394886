import React from 'react';
import '../styles/minute.css';
import '../styles/images.css'; 

const Data = () => {
    return (
        <div className="content-container justified-text">
            <section className="general img-center">
                <img src="../images/data.jpg" alt="Data Policy" />
            </section>

            <h1 className="centered-text">Data Policy for Webster Systems Ltd</h1>
            <br />
            <p>Last updated: 2024</p>
            <p>
                At Webster Systems Ltd, we are committed to maintaining the trust and
                confidence of our visitors to our website. In this Privacy Policy, we've
                provided detailed information on when and why we collect personal
                information, how we use it, the limited conditions under which we may
                disclose it to others, and how we keep it secure.
            </p>

            <h2>Information Collection and Use</h2>
            <p>
                We collect information about you when you register on our site, place an
                order, subscribe to a newsletter, respond to a survey, or fill out a form. The
                information collected might include your name, email address, mailing
                address, phone number, and other relevant details. We use this information
                to personalize your experience, improve our website, improve customer
                service, and process transactions.
            </p>

            <h2>Data Protection</h2>
            <p>
                Your information, whether public or private, will not be sold, exchanged,
                transferred, or given to any other company for any reason whatsoever,
                without your consent, other than for the express purpose of delivering the
                purchased product or service requested by the customer.
            </p>

            <h2>Your Access to and Control Over Information</h2>
            <p>
                You may opt out of any future contacts from us at any time. You can do the
                following at any time by contacting us via the email address or phone
                number given on our website:
            </p>
            <ul>
                <li>See what data we have about you, if any.</li>
                <li>Change/correct any data we have about you.</li>
                <li>Have us delete any data we have about you.</li>
                <li>Express any concern you have about our use of your data.</li>
            </ul>

            <h2>Cookies and Tracking Technologies</h2>
            <p>
                We use cookies to help us remember and process the items in your
                shopping cart and understand and save your preferences for future visits.
                If you prefer, you can choose to have your computer warn you each time a
                cookie is being sent, or you can choose to turn off all cookies via your
                browser settings.
            </p>
        </div>
    );
};

export default Data;
