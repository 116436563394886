import React from 'react';

const StrategySystemsAndObjectives = ({ onImageClick }) => {
    return (
        <div className="strategy-systems-and-objectives">
            <p className="p-text">
                <u>Strategy, systems and objectives:</u> What's the vision, mission, structure, culture?
            </p>
            <div className="spacing">
                BA's may have to define or investigate tactics - or tactical options - that ensures their projects
                align with an organisation's strategy, produce conclusions or recommendations, and/or measure
                performance. Potential analysis includes measuring industry change, an organisation's internal
                capabilities, assessing SWOT and Ansoff, and coordinating the tactical implementation of a strategy
                using 7S, four view models, or the balanced scorecard. Prioritise it all using Eisenhower time: what
                is important is seldom urgent and what is urgent is seldom important.
            </div>
            <div className="business-analysis-table table-divider">
                <table className="table">
                    <thead>
                        <tr>
                            <th>SWOT analysis</th>
                            <th>Ansoff's matrix</th>
                            <th>Greiner's growth model</th>
                            <th>McKinsey's 7s model</th>
                            <th>Porter's Five Forces</th>
                            <th>Kaplan's Balanced Scorecard</th>
                            <th>Eisenhower time management</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tooltip-cell"><img src="../images/swot.gif" onClick={() => onImageClick('../images/swot.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/ansoff.gif" onClick={() => onImageClick('../images/ansoff.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/growth.gif" onClick={() => onImageClick('../images/growth.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/seven_s_model.gif" onClick={() => onImageClick('../images/seven_s_model.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/five_forces.gif" onClick={() => onImageClick('../images/five_forces.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/balanced_scorecard.gif" onClick={() => onImageClick('../images/balanced_scorecard.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/eisenhower_time.gif" onClick={() => onImageClick('../images/eisenhower_time.gif')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default StrategySystemsAndObjectives;
