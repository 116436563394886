import React from 'react';

const RequirementsAnalysis = ({ onImageClick }) => {
    return (
        <div className="requirements-analysis">
            <p className="p-text">
                <u>Requirements II, Analysis:</u> How do we get on with it?
            </p>
            <div className="spacing">
                Structure and organise requirements into functional and non-functional potential actions. Assess
                where requirements ought to be prioritised and estimate delivery. Prepare for requirements
                negotiation and review. Use timeboxing and MoSCoW to analyse and set negotiated delivery dates for
                products. Actions, owners and due dates may be elements within a timebox. Manage further stakeholder
                reviews.
            </div>
            <div className="requirements-analysis-table table-divider">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Structuring requirements</th>
                            <th>Negotiating requirements</th>
                            <th>Moscow prioritisation</th>
                            <th>Timeboxing</th>
                            <th>Agile</th>
                            <th>Scrum</th>
                            <th>Negotiating</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tooltip-cell"><img src="../images/req_structure.gif" onClick={() => onImageClick('../images/req_structure.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/req_negotiation.gif" onClick={() => onImageClick('../images/req_negotiation.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/moscow.gif" onClick={() => onImageClick('../images/moscow.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/timebox.gif" onClick={() => onImageClick('../images/timebox.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/agile.gif" onClick={() => onImageClick('../images/agile.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/sprint.gif" onClick={() => onImageClick('../images/sprint.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/req_negotiation2.gif" onClick={() => onImageClick('../images/req_negotiation2.gif')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RequirementsAnalysis;
