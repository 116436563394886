import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/minute.css';

const Teamwork = () => {
    return (
        <div className="content-container">
            <h2 className="centered-text">Hackman's misperceptions about teamwork</h2>
            <div className="justified-text">
<p align="justify">
               From his <a href="https://blogs.hbr.org/2011/06/six-common-misperceptions-abou/">HBR article</a>
</p>
<p></p>
<table cellpadding="10" cellspacing="0" width="100%">
<thead>
<tr>
<th>Misperception</th>
<th>When actually</th>
</tr>
</thead>
<tr>
<td width="250"><b>Harmony helps</b></td>
<td width="*">
              Quite the opposite, research shows. Conflict, when well managed
              and focused on a team's objectives, can generate more creative
              solutions than one sees in conflict-free groups. So long as it is
              about the work itself, disagreements can be good for a team.
            </td>
</tr>
<tr>
<td><b>It's good to mix it up</b></td>
<td>
              The longer team members stay together as an intact group, the
              better they do.
            </td>
</tr>
<tr>
<td><b>Bigger is better</b></td>
<td>
              Excessive size is one of the most common - and also one of the
              worst - impediments to effective collaboration.
            </td>
</tr>
<tr>
<td><b>Face-to-face interaction is over</b></td>
<td>
              Teams working remotely are at a considerable disadvantage. There
              really are benefits to sizing up your teammates face-to-face.
            </td>
</tr>
<tr>
<td><b>It all depends on the leader</b></td>
<td>
              The hands-on activities of group leaders do make a difference. But
              the most powerful thing a leader can do to foster effective
              collaboration is to create conditions that help members
              competently manage <i>themselves</i>.
            </td>
</tr>
<tr>
<td><b>Teamwork is magical</b></td>
<td>
              It takes careful thought and no small about amount of preparation
              to stack the deck for success. The best leaders provide a clear
              statement of just what the team is to accomplish, and they make
              sure that the team has all the resources and supports it will need
              to succeed.
            </td>
</tr>
</table>
            </div>
        </div>
    );
};

export default Teamwork;
