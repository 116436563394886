import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/minute.css';

const Value = () => {
    return (
        <div className="content-container">
            <h2 className="centered-text">Competitive activities</h2>
            <div className="justified-text">
<p align="justify">
        Arguably the most famous and useful book on business strategy is
        Porter's competitive advantage. In it he claims long term competitive
        advantage is about value, instead of cost, and asks two fundamental
        questions:
      </p>
<p>
<b>- How attractive are different types of industry over the long
          term?</b>
<br />
<b>- What position can be taken in that industry relative to the
          competition?</b>
</p>
<p>
        He suggests that low cost labour and few resources are ubiquitous (ie
        all industries have to cope with low cost labour and a lack of
        resources), and so the better strategy is for a project to produce high
        quality results offered at high prices. This results in "good
        competition" in an industry and leads to industry attractiveness,
        compared to "bad competition", which has the opposite effect.
      </p>

            </div>
        </div>
    );
};

export default Value;
