import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/minute.css';

const GroundsAndSkeletons = () => {
    return (
        <div className="content-container">
            <h2 className="centered-text">Grounds of appeal and Skeleton arguments</h2>
            <div className="justified-text">

<table cellpadding="10" cellspacing="0" width="100%">
<thead>
<tr>
<th>Grounds of appeal</th>
<th>Skeleton arguments</th>
</tr>
</thead>
<tr>
<td valign="top">
              (1) The <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part52/practice-direction-52c-appeals-to-the-court-of-appeal#5.1">grounds of appeal</a> must 
              identify as precisely as possible the respects in which the
              judgment of the court below is (a) wrong, or (b) unjust because of
              a serious prodecural or other irregularity, as required by <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part52#52.11">CPR rule 52.11(3)</a>.

              <p>
                (2) The reasons why the decision under appeal is wrong or unjust
                must not be included in the grounds of appeal and must be
                confined to the skeleton argument.
              </p>
</td>
<td>
<p>
                (1) The <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part52/pd_part52#V">skeleton argument</a> is 
                to assist the court by setting out as concisely as practicable the arguments upon which a party intends to rely.
              </p>
<p>(2) A skeleton argument must:</p>
<ul>
<li>be concise</li>
<li>define and confine the areas of controversy</li>
<li>be set out in numbered paragraphs</li>
<li>
                  be cross-referenced to any relevant document in the bundle
                </li>
<li>
                  be self-contained and not incorporate by reference material
                  from previous skeleton arguments
                </li>
<li>
                  not include extensive quotations from documents or authorities
                </li>
</ul>
<p>(3) Documents to be relied apon must be identified</p>
<p>
                (4) Where it is necessary to refer to an authority, the skeleton
                argument must (a) state the proposition of law the authority
                demonstrates; and (b) identify the parts of the authority that
                support the proposition. If more than one authority is cited in
                support of a given proposition, the skeleton argument must
                briefly state why.
              </p>
</td>
</tr>
</table>
</div>
</div>
)};

export default GroundsAndSkeletons;
