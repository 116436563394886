import React from 'react';

const RequirementsDevelopment = ({ onImageClick }) => {
    return (
        <div className="requirements-development">
            <p className="p-text">
                <u>Requirements III, Development:</u> So what are we doing?
            </p>
            <div className="spacing">
                Requirements need to be structured and documented into a specification. That usually contains an
                introduction, functional model(s), data model(s), a catalogue of requirements, and glossary. Each
                item in the catalogue needs an ID, name, owner, stakeholder, priority, description, acceptance
                criteria and validation. Baselining, configuration and version controls are techniques used to
                manage changing requirements. A behaviour tree is a useful method of managing large numbers of
                requirements and mapping dependencies between them.
            </div>
            <div className="requirements-development-table table-divider">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Requirements user case</th>
                            <th>Behaviour tree</th>
                            <th>Behaviour tree integration</th>
                            <th>Behaviour tree mapping</th>
                            <th>Requirements management</th>
                            <th>Acceptance criteria</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tooltip-cell"><img src="../images/behaviour_tree.gif" onClick={() => onImageClick('../images/behaviour_tree.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/behaviour_tree2.gif" onClick={() => onImageClick('../images/behaviour_tree2.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/behaviour_tree3.gif" onClick={() => onImageClick('../images/behaviour_tree3.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/behaviour_tree4.gif" onClick={() => onImageClick('../images/behaviour_tree4.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/behaviour_tree5.gif" onClick={() => onImageClick('../images/behaviour_tree5.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/req_acceptance.gif" onClick={() => onImageClick('../images/req_acceptance.gif')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RequirementsDevelopment;
