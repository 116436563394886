import React from 'react';

const RequirementsElicitation = ({ onImageClick }) => {
    return (
        <div className="requirements-elicitation">
            <p className="p-text">
                <u>Requirements I, Elicitation:</u> Where do we start?
            </p>
            <div className="spacing">
                Per analysing needs, when interviewing stakeholders it's easy to get entrenched in the detail of the
                current situation and existing issues, and hence not focus on what's really needed in future. The
                skill is to look beyond what's said by individual stakeholders and meet true business objectives.
                Try user scenarios, storyboarding and prototyping to elicit requirements.
            </div>
            <div className="requirements-elicitation-table table-divider">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Event scenarios</th>
                            <th>User stories</th>
                            <th>Storyboarding</th>
                            <th>Storyboarding with post its</th>
                            <th>Story prototypes</th>
                            <th>Prototype designing</th>
                            <th>Prototyping</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="tooltip-cell"><img src="../images/scenarios.gif" onClick={() => onImageClick('../images/scenarios.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/user_story.gif" onClick={() => onImageClick('../images/user_story.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/storyboard.gif" onClick={() => onImageClick('../images/storyboard.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/storyboarding.gif" onClick={() => onImageClick('../images/storyboarding.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/story_proto.gif" onClick={() => onImageClick('../images/story_proto.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/prototype.gif" onClick={() => onImageClick('../images/prototype.gif')} /></td>
                            <td className="tooltip-cell"><img src="../images/prototype2.gif" onClick={() => onImageClick('../images/prototype2.gif')} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RequirementsElicitation;
