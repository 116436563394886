import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/minute.css';

const Strategy = () => {
    return (
        <div className="content-container">
            <h2 className="centered-text">Strategy</h2>
            <div className="justified-text">
	<p align="justify">A definition of entrepreneurial strategy is to take risk to pursue opportunity
		beyond resources currently controlled, or not to do so. It's about growing the business, and managing the
		risk of growing the business. Reflecting on "strategy", the word comes from ancient greek <i>stratgi</i>, meaning "office of
		a general". Also <i>stratos</i> means army. A general has an army of resources to organise, even to recruit,
		train, operate and pay for. The army has to defend the country from enemies. In the military sense, 
		the environment can contain what are called strategic points, each a
		point or region which affords an opportunity for its possessor to gain an advantage over his opponent. A
		mountain pass, a junction of rivers or roads, a fortress, the use of spies, outposts and scouts. Picture a strategic line 
		joining strategic points and you have a potential success scenario, a
		vision, if you like. Securing each strategic point gains an advantage.
	</p>
	<p align="justify">Your competition wants to identify strategic points, and secure them. So apply strategic points
		to the business landscape. Each point adds value to the whole. For example, a brainstorm of strategic points
		may be:
	</p>
	<p align="justify">
	<ul>
		<li>Industry attractiveness over the long term. Can you create high quality products and charge high prices
				for them?</li>
		<li>Industry size and your market share. Who are customers going to pay for the value in the industry?
			What's the aggregate customer spend in the industry?</li>
		<li>Ideation and creativity. What's next for the industry?</li>
		<li>Theory X or Theory Y management. How do people manage best?</li>
		<li>Brainstorming all the activities required to deliver the product/service to customers, you get a huge
			amount of activities that have to be done.</li>
		<li>Recruiting the best people for the activities. Who does what and why?</li>
		<li>Examine how the links between different activities creates a value chain. Basically Michael Porter
			thinking on activities.</li>
		<li>Assess suppliers, customers and competitor activities. See where you can improve and grab a point. What's the market share of each competitor?</li>
		<li>The meshing and integration of each value chain creates a value system. ie each supplier, customer and
			competitor has a value chain and the value system is the way the whole industry operates. What points on
			the value system are up for grabs?</li>
		<li>Experimentation. Encouraging innovation in order to inspire and retain the best staff.</li>
		<li>Experimenting with adjacient activities from core activities to spur growth.</li>
		<li>Putting old ideas from one department to work in other departments. In a bigger company.</li>
		<li>Articulating vision, and articulating stories which inspire and direct value activities. This is to
			motivate staff, and customers, and shareholders.</li>
		<li>Measuring performance using metrics and scorecards. Each activity has to be measured.</li>
		<li>Investigating customer groups and determining segmentation criteria. Allows you to tailor your product
			and charge a higher price for it.</li>
		<li>Trading-off market segments and business processes to meet a focussed target. This is the core of
			business strategy classes. You have to prioritise...</li>
		<li>Ordering a competitive fit of resources. Action adds value.</li>
	</ul>
	<p align="justify">Other ideas are:</p>
	<ul>
		<li>Segmenting up the market according to separate business processes (value activities).</li>
		<li>Segmenting up the market according to competitive positions (value chains).</li>
		<li>Designing and matching new activities to a target market.</li>
		<li>Getting the show on the road to attract finance and funding.</li>
		<li>Starting operations and marketing processes.</li>
		<li>Maintaining an ability to monitor the environment for opportunities and threats.</li>
		<li>Maintaining strategic relationships with resource owners and providers.</li>
		<li>Managing the long run direction of the firms activities (visioning).</li>
		<li>Using the vision to create growth stages and goals for the organisation.</li>
		<li>Creating a culture to handle success, inspire teamwork and settle conflicts.</li>
		<li>Delegating profit centres to staff teams to encourage motivation.</li>
		<li>Considering the implications of change for the organisation.</li>
		<li>Saving time on operations to spend that time intensifying chosen processes.</li>
	</ul>
	<p align="justify">

		     By employing knowledge and skill, organisations plan to gain strategic
		points or perform strategic <b>value activities</b>. A position on a visionary path ought to create a forward
		looking business culture, motivating employees to meet growth targets, and making stakeholders happy.</p>
		<b>Concepts for strategic thinking</b><br/>
		<br/>

1. Long term sustainable superior economic performance.<br/>
2. Relationship between functional disciplines.<br/>
3. Coordinate and fit relationship consistently.<br/>
4. Make the firm unique and distinctive.<br/>
5. The nature of strategy is in industry context, business level strategy, corporate level strategy, national and international contexts, corporate governance, change and rivalry.<br/>
6. How do firms decide to compete?<br/>
7. What makes a firm successful?<br/>
8. What is the nature and structure of the industry?<br/>
9. What is a good or bad industry or firm?<br/>
10. What is the nature and structure of a firm?<br/>
11. What is the nature of competition?<br/>
12. How do you make trade-offs in choosing activities to do and not to do?<br/>
</p>
            </div>
        </div>
    );
};

export default Strategy;
