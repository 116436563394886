import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/minute.css';

const ClaimsAndParticulars = () => {
    return (
        <div className="content-container">
            <h2 className="centered-text">Claim forms and particulars of claim</h2>
            <div className="justified-text">
<table cellpadding="10" cellspacing="0" width="100%">
<thead>
<tr>
<th>Claim forms</th>
<th>Particulars of claim</th>
</tr>
</thead>
<tr>
<td valign="top">
              The <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part16/pd_part16#2">claim form</a> generally 
              gives names and addresses for service
            </td>
<td>
              The claimant must <a href="https://www.justice.gov.uk/courts/procedure-rules/civil/rules/part16/pd_part16#8">specifically set out the following matters</a> in 
              his particulars of claim where he wishes to rely on them in support of his claim:

              <ul>
<li>any allegation of fraud</li>
<li>the fact of any illegality</li>
<li>details of any misrepresentation</li>
<li>details of all breaches of trust</li>
<li>notice or knowledge of a fact</li>
<li>details of unsoundness of mind or undue influence</li>
<li>details of wilful default</li>
<li>any facts relating to mitigation of loss or damage</li>
</ul>
</td>
</tr>
</table>

            </div>
        </div>
    );
};

export default ClaimsAndParticulars;
